<template>
    <div v-if=" perms.includes('Admin')">
      <div v-if="loading">
            <div id="loading-bg">
            <div class="loading">
            <div class="effect-1 effects"></div>
            <div class="effect-2 effects"></div>
            <div class="effect-3 effects"></div>
          </div>
        </div>
      </div>
        <section class="invoice-add-wrapper">
        <b-row class="invoice-add">
    
          <!-- Col: Left (Invoice Container) -->
          <b-col
            cols="12"
            xl="12"
            md="9"
          >
            <b-form @submit.prevent id="formdata">
              <b-card
                no-body
                class="invoice-preview-card"
              >
                <!-- Header -->
                <b-card-body class="invoice-padding pb-0">
    
                  <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
    
                    <!-- Header: Left Content -->
                    <div>
                      <div class="logo-wrapper">
                        <!-- <logo /> -->
                        <h3 class="text-primary invoice-logo">
                          Bon de commande
                        </h3>
                      </div>
                      <!-- <b-card-text class="mb-25">
                        AGADIR 
                      </b-card-text>
                      <b-card-text class="mb-25">
                        Adresse
                      </b-card-text>
                      <b-card-text class="mb-0">
                        +212 0645743797
                      </b-card-text> -->
                    </div>
    
                    <!-- Header: Right Content -->
                    <div class="invoice-number-date mt-md-0 mt-2">
                      <!-- <div class="d-flex align-items-center justify-content-md-end mb-1">
                        <h4 class="invoice-title">
                          Bon de commande N°
                        </h4>
                        <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="HashIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="invoice-data-id"
                            v-model="NumFact"                        
                          />
                        </b-input-group>
                      </div> -->
                      <div class="d-flex align-items-center justify-content-md-end mb-1">
                        <h4 class="invoice-title">
                          Date:
                        </h4>
                        <b-form-input
                              v-model="DateBC"
                              type="date"
                        />
                        <!-- <flat-pickr
                        format="MM/DD/yyyy"
                          v-model="DateBC"
                          class="form-control invoice-edit-input"
                        /> -->
                      </div>
                    </div> 
                  </div>
                </b-card-body>
    
                 <b-card-body
                  class="invoice-padding pt-0"
                >
                  <b-row >
    
                    <!-- Col: Invoice To -->
                    <b-col
                      cols="12"
                      xl="12"
                      class="mb-lg-1"
                    >
                      <h6 class="mb-3">
                        Choisir le fournisseur:
                      </h6>
    
                      <!-- Select Client -->
                      <v-select style="border: 1px solid #0000002b;  border-radius: 0.357rem;"
                        v-model="invoiceData.client"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="fournis"
                        label="NomSociete"
                        input-id="invoice-data-client"
                        :clearable="false"
                        @input="getProduct(invoiceData.client.id)"
                      
                      >
                    
                        <template #list-header>
                            <!-- v-b-toggle.sidebar-invoice-add-new-customer -->
                          <li
                            @click="addfourni()"
                            class="add-new-client-header d-flex align-items-center my-50"
                          >
                            <feather-icon
                              icon="PlusIcon"
                              size="16"
                            />
                            <span class="align-middle ml-25">Ajouter nouveau fournisseur</span>
                          </li>
                        </template>
                      </v-select>                
                      
                      
                           
                           
    
                      <!-- Selected Client -->
                      <div
                        v-if="invoiceData.client"
                        class="mt-1"
                      >
                        <h6 class="mb-25">
                          {{ invoiceData.client.NomSociete }}
                        </h6>
                        <b-card-text class="mb-25">
                           {{ invoiceData.client.Ice }}
                        </b-card-text>
                        <b-card-text class="mb-25">
                          {{ invoiceData.client.adresse }}, Maroc
                        </b-card-text>
                        <b-card-text class="mb-25">
                          {{ invoiceData.client.phone }}
                        </b-card-text>
                        <!-- <b-card-text class="mb-0">
                          {{ invoiceData.client.companyEmail }}
                        </b-card-text> -->
                      </div>
                    </b-col>
                  </b-row>
                </b-card-body>
                    <b-card-body class="invoice-padding form-item-section">
                      <div
                        ref="form"
                        class="repeater-form"
                      >
                      <!-- :style="{height: trHeight}" -->
                        <b-row
                          v-for="(item, index) in FactureData.produits"
                          :key="index"
                          ref="row"
                          class="pb-2"
                        >
    
                          <!-- Item Form -->
                          <!-- ? This will be in loop => So consider below markup for single item -->
                          <b-col cols="12">
    
                            <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                            <div class="d-none d-lg-flex">
                              <b-row class="flex-grow-1 px-1">
                                <!-- Single Item Form Headers -->
                                <b-col
                                  cols="12"
                                  lg="5"
                                >
                                  Produit
                                </b-col>
                                <b-col
                                  cols="12"
                                  lg="3"
                                >
                                  Prix Achat
                                </b-col>
                                <b-col
                                  cols="12"
                                  lg="2"
                                >
                                  Quantité
                                </b-col>
                                <b-col
                                  cols="12"
                                  lg="2"
                                >
                                  Total H.T
                                </b-col>
                              </b-row>
                              <div class="form-item-action-col" />
                            </div>
    
                            <!-- Form Input Fields OR content inside bordered area  -->
                            <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                            <div class="d-flex border rounded">
                              <b-row class="flex-grow-1 p-2">
                                <!-- Single Item Form Headers -->
                                <b-col
                                  cols="12"
                                  lg="5"
                                >
                               
                                  <label class="d-inline d-lg-none">Produit</label>
                                  <!--  v-model="item.Nom" -->
                                  
                                  <v-select style="border: 1px solid #0000002b;  border-radius: 0.357rem;"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="Products"
                                    label="Nom"  
                                    :clearable="false"
                                    class="mb-2 item-selector-title"
                                    placeholder="Choisir produit"
                                    @input="val => updateItemForm(index, val)"
                                  />
                                </b-col>
                                <b-col
                                  cols="12"
                                  lg="3"
                                >
                                  <label class="d-inline d-lg-none">Prix Achat</label>
                                  <b-form-input
                                    v-model="item.Prix_Achat"
                                    type="number"
                                    class="mb-2"
                                    disabled
                                  />
                                </b-col>
                                <b-col
                                  cols="12"
                                  lg="2"
                                >
                                  <label class="d-inline d-lg-none">Quantité</label>
                                  <b-form-input
                                    v-model="item.qnt"
                                    type="number"
                                    class="mb-2"
                                    :name="'qnty'+index"
                                    :class="error=='qnty'+index ? 'is-invalid':null"
                                    
                                  />
                                  <!-- @change="checkQnty(item.id,item.qnt,'qnty'+index)" -->
                                  <!-- :name="'qnty'+index"
                                    :class="prod.quantite<item.qnt ? 'is-invalid':null"
                                     @input="checkQnty(prod.quantite,item.qnt,'qnty'+index)" -->
                                   <template v-if="error=='qnty'+index">
                                     <small class="text-danger">Quantité  est insuffisante</small>
                                  </template>
                                 
                                </b-col>
                                <b-col
                                  cols="12"
                                  lg="2"
                                >
                                  <label class="d-inline d-lg-none">Total H.T</label>
                                  <p class="mb-1" v-if="item.qnt!=''">
                                    {{ item.Prix_Achat * item.qnt }} DH
                                  </p>
                                  <p v-else class="mb-1" >
                                    0 DH
                                  </p>
                                </b-col>
                               
                              </b-row>
                              <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                                <feather-icon
                                  size="16"
                                  icon="XIcon"
                                  class="cursor-pointer"
                                  @click="removeItem(index)"
                                />
                                <!-- <feather-icon
                                  :id="`form-item-settings-icon-${index}`"
                                  size="16"
                                  icon="SettingsIcon"
                                  class="cursor-pointer"
                                /> -->
    
                                <!-- Setting Item Form -->
                               
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        size="sm"
                        variant="primary"
                        @click="addNewItemInItemForm" >
                        Ajouter Produit
                      </b-button>
                    </b-card-body>
                <!-- Invoice Description: Total -->
                <b-card-body class="invoice-padding pb-0">
                  <b-row>
            
                     <b-col
                      cols="12"
                      md="12"
                      class="mt-md-0 mt-12 d-flex "
                      order="2"
                      order-md="1"
                    >
                        <b-card-body class="invoice-padding pt-0">
                  <span class="font-weight-bold">Note :  </span>
                  <b-form-textarea v-model="note"  />
                </b-card-body>
                     </b-col>
                   
                  
                  </b-row>
                </b-card-body>
    
                <!-- Spacer -->
                <hr class="invoice-spacing">
                <b-col
                  cols="12"
                      md="12"
                      class="mt-md-0 mt-12 d-flex "
                      order="2"
                      order-md="1">
                    <b-card-body class="invoice-padding pt-0">
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mb-75"
                            block
                            @click="addFacture()" > Enregistrer le bon de commande
                        </b-button>
                    </b-card-body>
                </b-col>
                <!-- Note -->
                
              </b-card>
            </b-form>
    
          </b-col>
    
          <!-- Right Col: Card -->
          <!--   <b-col
            cols="12"
            md="4"
            xl="3"
            class="invoice-actions mt-md-0 mt-2"
          >
           <b-card>
              <input type="file"  accept="application/pdf,image/*" class="hidden" ref="updateImgInput" multiple="multiple" @change="imageSelected" >
              <b-button style="margin-bottom: 20px;"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                block
                @click="$refs.updateImgInput.click()"
              >
                Importer Pièces Jointes
              </b-button>
              <b-media-aside>
                  <div v-for="(attachment, index) in attachments " :key="index">
                    <span class="label label-primary">{{ attachment.name + ' (' + Number((attachment.size / 1024 / 1024).toFixed(1)) + 'MB)'}}<br></span>
                  </div>
            </b-media-aside> 
           
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mb-75"
                block
                @click="addFacture()"
                
              >
                Enregistrer
              </b-button>
    
             <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="mb-75"
                block
              >
                Voir
              </b-button> 
          
            </b-card> 
          </b-col>-->
        </b-row>
      </section>
    </div>
    <div v-else>
      <not-authoriz></not-authoriz>
    </div>
    </template>
    
    <script>
    import config from '@/config'
    import moment from 'moment';
    import Logo from '@core/layouts/components/Logo.vue'
    import { ref, onUnmounted } from '@vue/composition-api'
    import { heightTransition } from '@core/mixins/ui/transition'
    import Ripple from 'vue-ripple-directive'
    import store from '@/store'
    import {
      BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormGroup, BFormInput, BInputGroup, 
      BInputGroupPrepend, BFormTextarea, BFormCheckbox, BPopover, VBToggle,
      BMedia, BMediaAside, BMediaBody, BLink,BImg
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import flatPickr from 'vue-flatpickr-component'
    import invoiceStoreModule from '../../FactureFournisseur/invoiceStoreModule' 
    import NotAuthoriz from '../../NotAuthoriz.vue'
    
    // src\views\admin\FactureFournisseur\useInvoiceList.js
    // import InvoiceSidebarAddNewCustomer from '../InvoiceSidebarAddNewCustomer.vue'
    
    export default {
      components: {
        BRow,
        BCol,
        BCard,
        BCardBody,
        BButton,
        BCardText,
        BForm,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupPrepend,
        BFormTextarea,
        BFormCheckbox,
        BPopover,
        BMedia, BMediaAside, BMediaBody, BLink,BImg,
        flatPickr,
        vSelect,
        Logo,
        NotAuthoriz
        // InvoiceSidebarAddNewCustomer,
      },
      directives: {
        Ripple,
        'b-toggle': VBToggle,
    
      },
      mixins: [heightTransition],
      data(){
          return{
            perms:[],
            loading : false,
              fournis:[],
              Products:[],
              DateBC:new Date().toISOString().slice(0,10),
              total:0,
              qnt:0,
              note:'',
              image:null,
              imagepreview:null,
              attachments:[],
              prod:'',
              errorQnty:'',
              error:false,
              fourniId:0,
              UserID:{},
    
    
          }
      },
      mounted() {
        this.initTrHeight()
         this.perms=JSON.parse(localStorage.getItem('access'));
        this.loading = true
        this.$http.get(`${config.API_BASE_URL}/api/auth/getAllFournis`).then(res => { this.fournis = res.data ,this.loading = false})
        this.UserID=JSON.parse(localStorage.getItem('userData'))
          //  this.$http.get(`${config.API_BASE_URL}/api/auth/getProducts`).then(res => { this.Products = res.data, this.loading = false })
      },
      created(){
        window.addEventListener('resize', this.initTrHeight)
       
      },
      destroyed(){
        window.removeEventListener('resize', this.initTrHeight)
      },
      methods: {
        getProduct(id){
              this.fourniId=id
              this.$http.post(`${config.API_BASE_URL}/api/auth/getProductFourni/${id}`)
          .then(res => { this.Products = res.data })
        },
        imageSelected(e){
            let selectedFiles=e.target.files;
                if(!selectedFiles.length){
                    return false;
                }
                for(let i=0;i<selectedFiles.length;i++){
                    this.attachments.push(selectedFiles[i]);
                }
                console.log(this.attachments);
        },
        
        checkQnty(id,qnt,text){
          console.log(id+'/'+qnt+'/'+text)
           this.$http.get(`${config.API_BASE_URL}/api/auth/CheckQnty/${id}/${qnt}`)
                .then(res => {
                      if(res.data.message=='Qnty Insufissante'){
                         this.error=text
                      }else if(res.data.message=='success'){
                        this.error=''
                      }
                })
            // if(qnt1<qnt2){
            //   this.error=text
            //   this.errorQnty="Quantité de ce produit est insiffisante"
            // }else{
            //   this.error='',
            //     this.errorQnty=""
            // }
        },
        AddProd(){
          let data = new FormData;
              data.append('UserID', this.UserID.id);
              data.append('products', JSON.stringify(this.FactureData.produits));
              data.append('id_fournis', this.fourniId);
              data.append('note', this.note);
              // data.append('DateBC', moment(String(this.DateBC)).format('DD/MM/YYYY'));
              data.append('DateBC', this.DateBC);
       
              this.$http.post(`${config.API_BASE_URL}/api/auth/newBonCmd`,data)
                .then(res => { 
                    if(res.data=='success'){
                        this.$swal.fire({
                      position: 'top-end',
                      icon: 'success',
                      title: 'Votre Bon d\'avoir  est bien ajouté',
                      showConfirmButton: false,
                      timer: 1000 })
                      document.getElementById("formdata").reset();
                      this.attachments=[];
                      this.$router.push('/bonCommandes');
    
    
                    }else if(res.data.message=='Qnty Insufissante'){
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Quantité Insuffisante',
                            text: 'La quantité du produit '+res.data.Prod+' est insuffisante !' })
                    
                    }else{
                          this.$swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong! Pleaz try again'
                  })
                    }
                    
                    })
        },
        addFacture(){
          if(this.FactureData.produits.length==0  || this.DateBC==''){
             this.$swal.fire({
                  icon: 'error',
                  title: 'Champs vides',
                  text: 'Merci de remplire tous les champs' })
          }
          else if(this.FactureData.produits.length==1){
             for(let i=0;i<this.FactureData.produits.length;i++){
                if(this.FactureData.produits[i].Nom===null &&  this.FactureData.produits[i].id===0){
                      this.$swal.fire({
                      icon: 'error',
                      title: 'Pas de produit',
                      text: 'Merci d\'ajouter des produits avant de passer votre bon d\'avoir !' })
                }else{
                  this.AddProd();
                }
            }
          }
          
          else if(this.FactureData.produits.length>0 ){
                 this.AddProd();
            }
              
          },
        addNewItemInItemForm() {
          this.$refs.form.style.overflow = 'hidden'
          this.FactureData.produits.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))
        this.$nextTick(() => {
            this.trAddHeight(this.$refs.row[0].offsetHeight)
            setTimeout(() => {
              this.$refs.form.style.overflow = null
            }, 350)
          })
        },
        removeItem(index) {
          this.FactureData.produits.splice(index, 1)
          this.trTrimHeight(this.$refs.row[0].offsetHeight)
        },
        initTrHeight() {
          this.trSetHeight(null)
          this.$nextTick(() => {
            this.trSetHeight(this.$refs.form.scrollHeight)
          })
        },
      },
      setup() {
        const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'
    
        // Register module
        if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)
    
        // UnRegister on leave
        onUnmounted(() => {
          if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
        })
    
        const clients = ref([])
        store.dispatch('app-invoice/fetchClients')
          .then(response => { clients.value = response.data })
    
        const itemFormBlankItem = {
          Nom: null,
          id:0,
          Prix_Achat: 0,
          qnt: 0,
        }
        
        const FactureData= ref({
            produits :[JSON.parse(JSON.stringify(itemFormBlankItem))],
        })
        const invoiceData = ref({
          id: 5037,
          client: null,
    
          // ? Set single Item in form for adding data
          items: [JSON.parse(JSON.stringify(itemFormBlankItem))],
        })
    
        
         const updateItemForm = (index, val) => {
          const { Prix_Achat, id, Nom } = val
          FactureData.value.produits[index].Prix_Achat = Prix_Achat
          FactureData.value.produits[index].id = id
          FactureData.value.produits[index].Nom = Nom
        //   FactureData.value.produits[index].total = Prix_Achat*quantite
        }
    
    
    
        return {
          FactureData,
          invoiceData,
          clients,
          updateItemForm,
          itemFormBlankItem,
        }
      },
    }
    </script>
    
    
    <style scoped>
    .media-aside.align-self-start {
        display: grid;
    }
    </style>
    <style lang="scss" scoped>
    @import "~@core/scss/base/pages/app-invoice.scss";
    @import '~@core/scss/base/components/variables-dark';
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    .invoice-add-wrapper {
      .add-new-client-header {
        padding: $options-padding-y $options-padding-x;
          color: $success;
    
        &:hover {
          background-color: rgba($success, 0.12);
        }
      }
    }
    .form-item-section {
    background-color:$product-details-bg;
    }
    
    .form-item-action-col {
      width: 27px;
    }
    
    .repeater-form {
      // overflow: hidden;
      transition: .35s height;
    }
    
    .v-select {
      &.item-selector-title,
      &.payment-selector {
        background-color: #fff;
    
        .dark-layout & {
          background-color: unset;
        }
      }
    }
    
    .dark-layout {
      .form-item-section {
        background-color: $theme-dark-body-bg;
    
        .row .border {
          background-color: $theme-dark-card-bg;
        }
    
      }
    }
    </style>
    ./newBonCmd.vue